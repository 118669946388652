


































import { Component, Vue } from 'vue-property-decorator';

import { Step, Stepper } from '@/components/stepper';
import { Prognosis } from '@/models';
import * as CoronaComponents from '@/components/corona';
import CoronaLeavePopup from '@/components/corona/LeavePopup.vue';
import { PrognosisService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { NavigationGuardNext, Route } from 'vue-router';

@Component({
  components: {
    Stepper,
    Step,

    ...CoronaComponents,
  },
})
export default class CoronaPage extends Vue {
  data: Prognosis | null = null;
  loadedFirst: boolean = false;

  $refs: {
    stepper: Stepper;
    leave: CoronaLeavePopup;
  };

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (!this.$refs.leave.isOpen && !this.$refs.leave.stopNagging) {
      this.$refs.leave.display(to.path);
      next(false);
    } else {
      next();
    }
  }

  async mounted(): Promise<void> {
    try {
      const response = await PrognosisService.get();
      this.data = response || new Prognosis();
    } catch (e) {
      this.data = new Prognosis();
    }

    if (this.$route.query['step']) {
      const num = +this.$route.query['step'];
      if (num === 0) {
        return;
      }

      // Select step on next tick, as the stepper component hasn't been loaded
      // yet (as we are still in the tick that we are changing loaded to true)
      Vue.nextTick(() => this.$refs.stepper.select(num - 1));
    }
  }

  async onStep(): Promise<void> {
    if (!this.data) return;
    if (!this.loadedFirst) {
      this.loadedFirst = true;
      return;
    }

    try {
      await PrognosisService.update(this.data.serialize());
    } catch (e) {
      this.$toaster.error('Saving data failed', unwrapError(e));
    }
  }
}
