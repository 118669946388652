




















































































import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { CoronaDebt } from '@/lib/corona/debt';

@Component({
  components: {},
})
export default class CoronaDebtsPage extends Vue {
  @Model('change')
  debts!: CoronaDebt[];

  newLine(): void {
    const newLine = new CoronaDebt('', 0, 1, new Date(2020, 4, 1));
    this.debts.push(newLine);
  }

  deleteLine(idx: number): void {
    this.debts.splice(idx, 1);
  }
}
