
















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class CoronaLeavePopup extends Vue {
  isOpen: boolean = false;
  stopNagging: boolean = false;

  destination: string = '/';

  display(destination: string): void {
    this.isOpen = true;
    this.destination = destination;
  }

  beforeMount(): void {
    this.stopNagging =
      null !== window.localStorage.getItem('prognose-leave-popup');
  }

  close(): void {
    this.isOpen = false;
    if (this.stopNagging) {
      window.localStorage.setItem('prognose-leave-popup', 'accepted');
    }
  }
}
