





















































































































































































import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { CoronaData } from '@/lib/corona';
import { LiquidityMatrix } from '@/lib/corona/liquidity_matrix';
import { isString, round } from 'lodash-es';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LegalForm } from '@/lib/corona/costs';
import Papa from 'papaparse';
import { downloadBlob } from '@/lib/download';

function coronaMoney(value: number): string {
  const opts: any = {
    currency: 'EUR',
    style: 'currency',
  };
  const parts = Intl.NumberFormat('nl-NL', opts).formatToParts(round(+value));
  return parts
    .filter(
      (e) =>
        !(
          e.type === 'currency' ||
          e.type === 'literal' ||
          e.type === 'decimal' ||
          e.type === 'fraction'
        ),
    )
    .map((e) => e.value)
    .join('');
}

@Component({
  filters: {
    money: coronaMoney,
  },
})
export default class CoronaLiquidityPage extends Vue {
  @Model('change')
  data!: CoronaData;
  selectedYear: number = 0;

  $refs: {
    table: HTMLElement;
    lol: HTMLImageElement;
  };

  get matrix(): LiquidityMatrix {
    return this.data.liquidityMatrix(this.selectedYear);
  }

  async getTableImage(): Promise<string> {
    // Guess what, the message below wasn't the worst part
    const $rapportContainer = document
      .getElementsByClassName('rapport-container')
      .item(0) as HTMLElement;
    $rapportContainer && ($rapportContainer.style.overflow = 'visible');

    // I don't like this at all, but basically it wont render properly unless I
    // render the whole page.
    // For this purpose we figure out *where on the page* the table is, and then
    // crop a full page render
    // -- I hate it
    const tableBB = this.$refs.table.getBoundingClientRect();
    const canvas = await html2canvas(document.body, {
      x: tableBB.left,
      y: window.pageYOffset + tableBB.top,
      width: tableBB.width,
      height: tableBB.height,
    });

    $rapportContainer && ($rapportContainer.style.overflow = '');
    return canvas.toDataURL('image/png');
  }

  async renderPdf(): Promise<void> {
    const img = await this.getTableImage();
    const pdf = new jsPDF({
      orientation: 'landscape',
    });

    const logo = new Image();
    logo.src = require('@/assets/logos/thenextinvoice-grey.png');

    const imgProps = pdf.getImageProperties(img);
    const wantedWidth = pdf.internal.pageSize.getWidth() - 40;
    const wantedHeight = pdf.internal.pageSize.getHeight() - 60;

    // Try using the wanted width, if too wide, just maximize height instead
    let width = wantedWidth;
    let height = (imgProps.height * width) / imgProps.width;
    if (height > wantedHeight) {
      height = wantedHeight;
      width = (imgProps.width * height) / imgProps.height;
    }

    pdf.addImage(logo, 'PNG', 20, 3, 40, 15);
    pdf.setTextColor('#11998e');
    pdf.text('Rapport Liquiditeitsbegroting', width / 2 + 20, 10, {
      align: 'center',
    });
    pdf.addImage(img, 'PNG', 20, 25, width, height);

    pdf.setDrawColor('#34e77f');
    pdf.line(20, height + 30, width + 20, height + 30);
    pdf.setFontSize(8);
    pdf.setTextColor('#777777');
    pdf.text(this.data.disclaimer, 20, height + 38);
    pdf.save('liquiditeitsbegroting.pdf');
  }

  getCSV(): void {
    const months: string[] = [];
    for (let i = 0; i < 12; i++) {
      months.push(this.data.months[this.data.monthForIndex(i)]);
    }
    months.unshift(this.data.yearStringForIndex(this.selectedYear));

    // Hahahahahahaha
    // -- Kill me
    const rowRound = (e) => (isString(e) ? e : round(e).toString());
    const csvData = [months];

    const startingBank = this.matrix
      .getRow('startingBankBalance')
      .map(rowRound);
    startingBank.unshift('Beginstand Bank');
    const omzet = this.matrix.getRow('turnover').map(rowRound);
    omzet.unshift('Omzet');
    const loan = this.matrix.getRow('bankLoan').map(rowRound);
    loan.unshift('Bancaire Lening');
    const income = this.matrix.getRow('totalIncome').map(rowRound);
    income.unshift('Totale Inkomsten');
    const stockValue = this.matrix.getRow('stockValue').map(rowRound);
    stockValue.unshift('Inkoopwaarde van de omzet');
    const salary = this.matrix.getRow('salary').map(rowRound);
    salary.unshift('Loon en personeelskosten');
    const managementFee = this.matrix.getRow('managementFee').map(rowRound);
    managementFee.unshift('ManagementFee');
    const rent = this.matrix.getRow('rent').map(rowRound);
    rent.unshift('Huur en servicekosten');
    const exploitation = this.matrix.getRow('exploitation').map(rowRound);
    exploitation.unshift('Exploitatiekosten');
    const sales = this.matrix.getRow('sales').map(rowRound);
    sales.unshift('Verkoopkosten');
    const officeCosts = this.matrix.getRow('officeCosts').map(rowRound);
    officeCosts.unshift('Kantoorkosten');
    const restCosts = this.matrix.getRow('restCosts').map(rowRound);
    restCosts.unshift('Overige algemene kosten');
    const annuity = this.matrix.getRow('annuity').map(rowRound);
    annuity.unshift('Rente en afflosingskosten');
    const incomeTax = this.matrix.getRow('incomeTax').map(rowRound);
    incomeTax.unshift('Loonheffing');
    const revenueTax = this.matrix.getRow('revenueTax').map(rowRound);
    revenueTax.unshift('Omzetbelasting (BTW)');
    const creditors = this.matrix.getRow('creditors').map(rowRound);
    creditors.unshift('Aflossen crediteuren');
    const privateWithdrawal = this.matrix
      .getRow('privateWithdrawal')
      .map(rowRound);
    privateWithdrawal.unshift('Prive opname');
    const totalExpenses = this.matrix.getRow('totalExpenses').map(rowRound);
    totalExpenses.unshift('Totaal uitgaven');
    const closingBankBalance = this.matrix
      .getRow('closingBankBalance')
      .map(rowRound);
    closingBankBalance.unshift('Eindstand bank');

    csvData.push(startingBank, omzet, loan, income, stockValue, salary);

    if (this.data.costs.legalForm === LegalForm.BV) {
      csvData.push(managementFee);
    }

    csvData.push(
      rent,
      exploitation,
      sales,
      officeCosts,
      restCosts,
      annuity,
      incomeTax,
      revenueTax,
      creditors,
    );

    if (this.data.costs.legalForm === LegalForm.EZ_VOF) {
      csvData.push(privateWithdrawal);
    }

    csvData.push(totalExpenses, closingBankBalance);

    const csv = Papa.unparse(csvData, {
      delimiter: ';',
    });
    const csvBlob = new Blob([csv]);

    downloadBlob(csvBlob, 'liquiditeitsrapport.csv');
  }
}
