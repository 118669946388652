


























































































import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { CoronaData } from '@/lib/corona';

@Component({
  components: {},
})
export default class CoronaRevenuePage extends Vue {
  @Model('change')
  data!: CoronaData;

  onUpdate(): void {
    this.$emit('change', this.data);
  }
}
