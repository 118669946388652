







































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class CoronaIntroPopup extends Vue {
  isOpen: boolean = true;
  stopNagging: boolean = false;

  beforeMount(): void {
    this.isOpen = null === window.localStorage.getItem('prognose-popup');
  }

  close(): void {
    this.isOpen = false;
    if (this.stopNagging) {
      window.localStorage.setItem('prognose-popup', 'accepted');
    }
  }
}
